


































import {Component, Vue} from "vue-property-decorator";
import {CompletedObjectiveQuery} from "../../graphql/workspace";
import { ObjectivePaginator} from "@/typescript/types";
//import Sprint from "@/components/board/sprint/Sprint.vue";
const Sprint = () => import("@/components/board/sprint/Sprint.vue");

@Component({
  components: {Sprint},
  apollo: {
    getCompletedObjectives: {
      query: CompletedObjectiveQuery,
      fetchPolicy: 'network-only',
      variables() {
        return {
          workspace_id: this.$store.state.workspace.id,
          board_id: (this.$store.state.board) ? this.$store.state.board.id : null ,
        }
      }
    }
  }
})
export default class CompletedObjectives extends Vue {
  getCompletedObjectives: ObjectivePaginator | null = null;
  isLoading: boolean = false;
  page = 1;
  newCount = 0;

  get completedObjectives(){
    if(this.getCompletedObjectives){
      //return [...this.getCompletedObjectives.data.slice().reverse()];
      return this.getCompletedObjectives.data;
    }
    return [];

  }

  get hasMorePages() {
    if (this.getCompletedObjectives) {
      return this.getCompletedObjectives.paginatorInfo.hasMorePages;
    }
    return false;
  }

  changePage() {
    this.page++;
    this.completedObjectives!.splice(this.completedObjectives.length - this.newCount, this.newCount);
    this.newCount = 0;

    this.$apollo.queries.getCompletedObjectives.fetchMore({
      variables: {
        page: this.page,
      },
      updateQuery: (previousResult, {fetchMoreResult}) => {

        return {
          getCompletedObjectives: {
            __typename: previousResult.getCompletedObjectives.__typename,
            /**
             * Merging the tag list
             */
            data: [...previousResult.getCompletedObjectives.data, ...fetchMoreResult.getCompletedObjectives.data],
            paginatorInfo: fetchMoreResult.getCompletedObjectives.paginatorInfo,
          },
        };
      },
    });


  }

  mounted() {
    this.$events.listen('objective-updated', (eventData: any) => {
    
      if(eventData.completed_at == null && eventData.status != 'completed') {
        this.getCompletedObjectives!.data.map((item: any) => {
          if(item.id != eventData.id) {
            return item;
          }
        })
       
        if(this.getCompletedObjectives!.paginatorInfo.total != 0) {
          this.getCompletedObjectives!.paginatorInfo.total -=1
        }

      }else{
        let index = this.getCompletedObjectives!.data.findIndex((item: any) => {
          return item.id == eventData.id;
        })
        if(index == -1) {
          this.getCompletedObjectives!.data.push(eventData)
        }
      }
    })
  }

}
